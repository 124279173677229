<template>
    <Form
        v-model="form"
        v-bind="$props"
        :component-error="componentError"
        :after-route="afterRoute"
        :navigation-steps.sync="navigationSteps"
        :is-component-loading.sync="isComponentLoading"
        :components="components"
        @close="close"
        @submit="submit"
    >
        <template #1>
            <BasicCreative
                v-show="form.step === 1"
                ref="basic-creative"
                v-model="creative"
                :dynamic-rules="form.rules.dynamic"
                :form.sync="form"
                :is-loading.sync="isComponentLoading.isBasicsLoading"
                :is-valid.sync="navigationSteps[0].canContinue"
                @errorMessage="setComponentError"
            />
        </template>
        <template #2>
            <NativeAssets
                ref="asset-creative"
                v-model="creative"
                :form.sync="form"
                :is-loading.sync="isComponentLoading.isAssetsLoading"
                :is-valid.sync="navigationSteps[1].canContinue"
                @errorMessage="setComponentError"
            />
        </template>
        <template #3>
            <CategorizationCreative
                v-show="form.step === 3"
                ref="categorization-creative"
                v-model="creative"
                :form.sync="form"
                :is-loading.sync="isComponentLoading.isCategorizationLoading"
                :is-valid.sync="navigationSteps[2].canContinue"
                @errorMessage="setComponentError"
            />
        </template>
    </Form>
</template>

<script>
import CreativeFormTemplate from '@/components/domain/creatives/CreativeFormTemplate';
import Form from '@/components/domain/creatives/Form';
import BasicCreative from '@/components/domain/creatives/shared/basics/BasicCreative';
import NativeAssets from '@/components/domain/creatives/native/assets/NativeCreativeAssets';
import CategorizationCreative from '@/components/domain/creatives/shared/categorization/CategorizationCreative';
import { CREATIVE_SERVICE_DOMAIN } from '@/components/domain/creatives/types';
import { creativeService } from '@/services/CreativeService';

export default {
    name: 'NativeCreativeForm',
    components: {
        Form,
        BasicCreative,
        NativeAssets,
        CategorizationCreative,
    },
    extends: CreativeFormTemplate,
    model: {
        prop: 'value',
        event: 'input',
    },
    data() {
        return {
            creativeService: creativeService(CREATIVE_SERVICE_DOMAIN.NATIVE),
            navigationSteps: [
                { name: 'Basics', canContinue: false, headline: 'Native Creative' },
                { name: 'Creative Assets', canContinue: true, headline: 'Creative Assets' },
                { name: 'Categorization', canContinue: true, headline: 'Categorization' },
            ],
            isComponentLoading: {
                isBasicsLoading: false,
                isAssetsLoading: false,
                isCategorizationLoading: false,
            },
        };
    },
};
</script>
