<template>
    <Form
        v-model="creative"
        :loading="isLoading"
        :submit-action="actionType"
        :after-route="afterRoute"
        :error-message="errorMessage"
        @close="close"
    />
</template>

<script>
import Form from '@/components/domain/creatives/html/Form';
import { ACTION_TYPES, CREATIVE_SERVICE_DOMAIN } from '@/components/domain/creatives/types';
import { creativeService } from '@/services/CreativeService';
import LoadCreativeByIdMixin from '@/components/domain/creatives/LoadCreativeByIdMixin';
import { emptyHtmlCreative } from '@/components/domain/creatives/html/emptyHtmlCreative';

export default {
    name: 'HtmlCreativeEdit',
    components: {
        Form,
    },
    mixins: [LoadCreativeByIdMixin],
    data() {
        return {
            creative: { ...emptyHtmlCreative },
            isLoading: true,
            afterRoute: 'html-creative-overview',
            actionType: ACTION_TYPES.EDIT,
            creativeService: creativeService(CREATIVE_SERVICE_DOMAIN.HTML),
        };
    },
    methods: {
        close() {
            this.creative = { ...emptyHtmlCreative };
        },
    },
};
</script>
