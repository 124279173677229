<template>
    <AudioCreativeForm
        v-model="creative"
        :after-route="afterRoute"
        :error-message="errorMessage"
        :loading="isLoading"
        :submit-action="actionType"
        @close="close"
    />
</template>

<script>
import AudioCreativeForm from '@/components/domain/creatives/audio/AudioCreativeForm';
import { ACTION_TYPES, CREATIVE_SERVICE_DOMAIN } from '@/components/domain/creatives/types';
import { creativeService } from '@/services/CreativeService';
import LoadCreativeByIdMixin from '@/components/domain/creatives/LoadCreativeByIdMixin';
import { getEmptyAudioCreativeData } from '@/components/domain/creatives/audio/getEmptyAudioCreativeData';

export default {
    name: 'AudioCreativeEdit',
    components: {
        AudioCreativeForm,
    },
    mixins: [LoadCreativeByIdMixin],
    data() {
        return {
            creative: getEmptyAudioCreativeData(),
            afterRoute: 'audio-creative-overview',
            actionType: ACTION_TYPES.EDIT,
            // Defined for LoadCreativeByIdMixin
            creativeService: creativeService(CREATIVE_SERVICE_DOMAIN.AUDIO),
            isLoading: false,
        };
    },
    methods: {
        close() {
            this.creative = getEmptyAudioCreativeData();
        },
    },
};
</script>
