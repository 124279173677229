<template>
    <div class="creative-management-mf" data-app>
        <VApp id="creative-management" class="creative-management">
            <SrAppContainer id="creative-management-content" class="mf-container gray-lightest">
                <template #sidebar>
                    <SideNavigation />
                </template>
                <template #content>
                    <RouterView />
                </template>
            </SrAppContainer>
        </VApp>
    </div>
</template>
<script>
import { SrAppContainer } from '@ads/design-system';
import SideNavigation from '@/components/navigation/SideNavigation';
import '@/app.scss';

export default {
    name: 'App',
    components: {
        SrAppContainer,
        SideNavigation,
    },
};
</script>
