import { iamService } from '@/services/iam/IamService';
import { BusinessEntity } from '@/services/iam/types';

const iamStore = {
    namespaced: true,
    state: {
        businessEntities: [],
    },
    mutations: {
        setBusinessEntities(state, entities): void {
            state.businessEntities = entities;
        },
    },
    actions: {
        async getBusinessEntities(context): Promise<BusinessEntity[]> {
            if (context.state.businessEntities.length) {
                return context.state.businessEntities;
            }
            const businessEntities = await iamService.listBusinessEntities();
            context.commit('setBusinessEntities', businessEntities);
            return context.state.businessEntities;
        },
    },
};

export default iamStore;
